import { subscribeToOrganizationMember } from './organization_member_channel'

document.addEventListener('DOMContentLoaded', () => {
  const currentOrganizationTag = getOrganizationTag()

  if (currentOrganizationTag) {
    subscribeToOrganizationMember(currentOrganizationTag?.content)
  }
})

function getOrganizationTag() {
  return document.querySelector('meta[name="current-organization-id"]')
}
