import { Controller } from '@hotwired/stimulus'
import debounce from 'lodash.debounce'

export default class extends Controller {
  static values = { delay: { type: Number, default: 200 }, minLen: { type: Number, default: 2 } }
  static targets = ['input', 'menu']

  toggleMenu(hide) {
    this.menuTarget.style.display = hide ? 'none' : 'block'
  }

  setValue(event) {
    event.preventDefault()
    const label = event.target.innerHTML
    this.inputTarget.value = label

    const onSelectEvent = new CustomEvent('bulmahead:select', {
      detail: {
        label: label,
        value: event.target.dataset.value,
        obj: event.target.dataset.obj ? JSON.parse(event.target.dataset.obj) : undefined,
      },
    })
    window.dispatchEvent(onSelectEvent)

    this.toggleMenu(true)
    return event.target.dataset
  }

  api() {
    throw new Error('You must implement the `api` method')
  }

  handleApi(event) {
    const value = event.target.value

    if (value.length <= this.minLenValue) {
      return
    }

    this.menuTarget.innerHTML = ''

    // Assuming `api` is a function defined elsewhere that returns a Promise
    this.api(value).then((suggestions) => {
      this.menuTarget.innerHTML = '<div class="dropdown-content bulmahead-dropdown"><ul></ul></div>'

      suggestions.forEach(({ label, value, obj }) => {
        const li = document.createElement('li')
        li.classList.add('dropdown-item')
        li.innerHTML = label
        li.dataset.value = value
        if (obj) li.dataset.obj = JSON.stringify(obj)
        this.menuTarget.querySelector('ul').appendChild(li)
      })

      this.toggleMenu(false)

      if (!suggestions.length) {
        const noResultsEl = document.createElement('div')
        noResultsEl.classList.add('px-4')
        noResultsEl.classList.add('py-2')
        noResultsEl.innerHTML = 'No results'
        this.menuTarget.childNodes[0].appendChild(noResultsEl)
      }
    })
  }

  inputChanged(event) {
    debounce(() => this.handleApi(event), this.delayValue)()
  }

  focusIn() {
    this.toggleMenu(false)
  }

  clickOutside(event) {
    if (
      !event.target.closest('.dropdown') &&
      (event.target === null || !event.target.classList.contains('dropdown-item'))
    ) {
      this.toggleMenu(true)
    }
  }

  menuClick(event) {
    if (!event.target.classList.contains('dropdown-item')) return

    this.setValue(event)
  }
}
