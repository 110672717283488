import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['error', 'loader', 'unreachable']

  static values = {
    serialNumber: String,
    organization: String,
    state: String,
  }

  connect() {
    this.fetchStatus()
  }

  disconnect() {
    if (this.statusFetchTimer) {
      clearTimeout(this.statusFetchTimer)
    }
  }

  toggleFormDisabled(disabled = true) {
    this.element.querySelectorAll('input, a, button').forEach((el) => {
      if (disabled) el.setAttribute('disabled', 'disabled')
      else el.removeAttribute('disabled')
      el.classList.toggle('is-disabled', disabled)
    })
  }

  beforeSend({ target }) {
    this.toggleFormDisabled()

    let buttonTarget = target

    if (buttonTarget.tagName === 'FORM') {
      buttonTarget = buttonTarget.querySelector('input[type="submit"]')
    }

    if (!buttonTarget) {
      console.error('Button target not found')
      return
    }

    buttonTarget.dataset.originalText = buttonTarget.value
    buttonTarget.value = 'In progress...'

    this.errorTarget.textContent = ''
    this.errorTarget.classList.add('is-hidden')
  }

  onSendError({ detail, target }) {
    const [data, ,] = detail

    if (data.error) {
      this.errorTarget.textContent = data.error
      this.errorTarget.classList.remove('is-hidden')
    }

    this.toggleFormDisabled(false)

    let buttonTarget = target

    if (buttonTarget.tagName === 'FORM') {
      buttonTarget = buttonTarget.querySelector('input[type="submit"]')
    }

    if (!buttonTarget) {
      console.error('Button target not found')
      return
    } else {
      buttonTarget.value = buttonTarget.dataset.originalText
    }
  }

  async fetchStatus() {
    this.stateValue = 'loading'

    try {
      const res = await fetch(`/systems/${this.serialNumberValue}/fetch_status`)
      const result = await res.json()

      if (!res.ok) {
        throw new Error(result.error)
      }

      this.stateValue = 'loading'
    } catch (err) {
      console.error(err)
      this.unreachableTarget.querySelector('.unreachable-title').innerHTML = err.message
      this.stateValue = 'unreachable'
    }

    this.statusFetchTimer = setTimeout(() => {
      if (this.stateValue !== 'loading' || !this.hasLoaderTarget) return

      this.unreachableTarget.querySelector('.unreachable-title').innerHTML =
        this.unreachableTarget.querySelector('.unreachable-title').dataset.defaultMessage
      this.stateValue = 'unreachable'
    }, 30000)
  }

  stateValueChanged() {
    switch (this.stateValue) {
      case 'loading':
        this.loaderTarget.classList.remove('is-hidden')
        this.unreachableTarget.classList.add('is-hidden')
        break
      case 'unreachable':
        this.loaderTarget.classList.add('is-hidden')
        this.unreachableTarget.classList.remove('is-hidden')
        break
    }
  }
}
