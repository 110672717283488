import { Controller } from '@hotwired/stimulus'
import { formatDateTime } from '../../javascript/datetime'

export default class extends Controller {
  static values = {
    serialNumber: String,
    state: { type: String, default: 'initial' },
    product: String,
  }

  static targets = [
    'loader',
    'unreachable',
    'error',
    'success',
    'details',
    'updateDetails',
    'currentVersion',
    'ipAddress',
    'desiredVersion',
    'succeedAt',
    'attemptedAt',
    'failedAt',
    'failureReason',
    'form',
    'progress',
  ]

  updateInProgress = false

  async connect() {
    await this.fetchFirmwareVersions()
    await this.fetchSystemDetails()

    if (this.updateInProgress) {
      this.startPolling()
    }
  }

  disconnect() {
    if (this.fetchTimeout) clearTimeout(this.fetchTimeout)
    if (this.fetchInterval) clearInterval(this.fetchInterval)
  }

  async fetchSystemDetails() {
    if (this.stateValue === 'loading') return

    this.stateValue = 'loading'

    try {
      const res = await fetch(`/systems/firmware/${this.serialNumberValue}/get_state`)
      const result = await res.json()

      if (!res.ok) {
        throw new Error(result.response)
      }

      this.currentVersionTarget.innerHTML = result.firmwareVersion?.semanticVersionNumber || 'N/A'
      this.ipAddressTarget.innerHTML = result.deviceAddress?.address || 'N/A'

      const desiredVersion = result.desiredFirmwareVersion?.semanticVersionNumber

      this.succeedAtTarget.classList.add('is-hidden')
      this.failedAtTarget.classList.add('is-hidden')
      this.failureReasonTarget.classList.add('is-hidden')

      const updateLog = result.deviceCommunicationSummaries
        .sort((a, b) => new Date(b.lastAttemptedAt) - new Date(a.lastAttemptedAt))
        .find((summary) => summary.type === 'UPDATE_FIRMWARE')

      if (!updateLog) {
        this.stateValue = 'idle'
        this.errorTarget.classList.remove('is-hidden')
        this.errorTarget.innerHTML = 'No update log found'
        this.progressTarget.classList.add('is-hidden')
        this.updateDetailsTarget.classList.add('is-hidden')
        return
      }

      const tz = document.querySelector('[data-time-format-timezone-value]')?.dataset?.timeFormatTimezoneValue

      const lastSucceededAt = new Date(updateLog.lastSucceededAt)
      const lastFailedAt = new Date(updateLog.lastFailedAt)
      const lastAttemptedAt = new Date(updateLog.lastAttemptedAt)

      if (lastSucceededAt > lastAttemptedAt) {
        this.succeedAtTarget.querySelector('strong').innerHTML = formatDateTime(updateLog.lastSucceededAt, tz)
        this.succeedAtTarget.classList.remove('is-hidden')
      } else if (lastFailedAt >= lastAttemptedAt) {
        this.failedAtTarget.querySelector('strong').innerHTML = formatDateTime(updateLog.lastFailedAt, tz)
        this.failedAtTarget.classList.remove('is-hidden')
        this.failureReasonTarget.querySelector('strong').innerHTML = updateLog.lastFailureReason
        this.failureReasonTarget.classList.remove('is-hidden')
      }

      this.progressTarget.classList.toggle('is-hidden', !desiredVersion)

      this.updateDetailsTarget.classList.toggle('is-hidden', !desiredVersion)

      if (desiredVersion) {
        // current attempt
        this.desiredVersionTarget.innerHTML = desiredVersion
        this.updateInProgress = true
        this.stateValue = 'idle'
        this.attemptedAtTarget.innerHTML = formatDateTime(updateLog.lastAttemptedAt, tz)
        this.startPolling()
        return
      }

      if (this.fetchInterval) clearInterval(this.fetchInterval)

      // failed or succeeded
      if (this.updateInProgress) {
        if (lastSucceededAt > lastFailedAt) {
          this.successTarget.classList.remove('is-hidden')
          this.successTarget.innerHTML = 'System was successfully updated'
        } else {
          this.errorTarget.classList.remove('is-hidden')
          this.errorTarget.innerHTML = 'System was not updated'
        }
      }

      this.updateInProgress = false

      this.stateValue = 'idle'
    } catch (err) {
      console.error(err)
      this.unreachableTarget.querySelector('.unreachable-title').innerHTML = err.message
      this.stateValue = 'unreachable'
    }

    this.fetchTimeout = setTimeout(() => {
      if (this.stateValue !== 'loading') return

      this.unreachableTarget.querySelector('.unreachable-title').innerHTML =
        this.unreachableTarget.querySelector('.unreachable-title').dataset.defaultMessage
      this.stateValue = 'unreachable'
    }, 600)
  }

  async fetchFirmwareVersions() {
    try {
      const res = await fetch(`/systems/firmware/versions?product=${this.productValue}`)
      const result = await res.json()

      if (!res.ok) {
        throw new Error(result.response)
      }

      const stateData = result.map((version) => ({
        label: version.label,
        value: version.id,
      }))

      const newFirmwareVersions = stateData
        .sort((a, b) => {
          return a.label.localeCompare(b.label)
        })
        .slice(-2)

      const selectInput = this.formTarget.querySelector('select')

      newFirmwareVersions.forEach((version) => {
        const option = document.createElement('option')
        option.value = version.value
        option.text = version.label
        selectInput.appendChild(option)
      })
    } catch (err) {
      console.error(err)
    }
  }

  toggleFormDisable(disabled) {
    this.formTarget.querySelectorAll('select').forEach((input) => {
      input.disabled = disabled
    })

    this.toggleSubmitButton(disabled)
  }

  toggleSubmitButton(disabled) {
    const btn = this.formTarget.querySelector('button')

    btn.disabled = disabled
  }

  stateValueChanged() {
    switch (this.stateValue) {
      case 'initial':
      case 'loading':
        this.loaderTarget.classList.remove('is-hidden')
        this.unreachableTarget.classList.add('is-hidden')
        this.detailsTarget.classList.add('is-hidden')
        break
      case 'unreachable':
        this.loaderTarget.classList.add('is-hidden')
        this.unreachableTarget.classList.remove('is-hidden')
        this.detailsTarget.classList.add('is-hidden')
        break
      case 'idle':
        this.loaderTarget.classList.add('is-hidden')
        this.unreachableTarget.classList.add('is-hidden')
        this.detailsTarget.classList.remove('is-hidden')
        this.toggleSubmitButton()
        this.toggleFormDisable()
        this.formTarget.classList.toggle('is-hidden', this.updateInProgress)
        break
      case 'updating':
        this.errorTarget.classList.add('is-hidden')
        this.errorTarget.innerHTML = ''
        this.successTarget.classList.add('is-hidden')
        this.successTarget.innerHTML = ''
        this.toggleFormDisable(true)
        break
    }
  }

  updateFirmware(event) {
    event.preventDefault()

    this.stateValue = 'updating'

    const formData = new FormData(this.formTarget)
    const firmwareVersion = formData.get('version')

    fetch(`/systems/firmware/${this.serialNumberValue}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      },
      body: JSON.stringify({
        version_id: firmwareVersion,
      }),
    }).then((res) => {
      if (!res.ok) {
        res.json().then(({ error }) => {
          this.errorTarget.classList.remove('is-hidden')
          this.errorTarget.innerHTML = error

          this.stateValue = 'idle'
        })

        return
      }

      this.fetchSystemDetails()
    })
  }

  startPolling() {
    if (this.fetchInterval) return

    this.fetchInterval = setInterval(() => {
      this.fetchSystemDetails()
    }, 15000)
  }
}
