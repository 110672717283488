document.addEventListener('input', (e) => {
  if (e.target.classList.contains('is-danger')) {
    e.target.classList.remove('is-danger')
    e.target.parentElement.querySelector('.help.is-danger')?.remove()
  }
})

document.addEventListener('DOMContentLoaded', () => {
  Array.from(document.querySelectorAll('input, textarea')).forEach((input) => {
    const parentField = input.closest('.field')

    if (!parentField) return

    const label = parentField.querySelector('label')
    const help = parentField.querySelector('.help')

    if (label) {
      input.addEventListener('focus', (e) => {
        label.classList.add('has-focus')
      })

      input.addEventListener('blur', (e) => {
        label.classList.remove('has-focus')
      })
    }

    if (help) {
      input.addEventListener('focus', (e) => {
        help.classList.add('has-focus')
      })

      input.addEventListener('blur', (e) => {
        help.classList.remove('has-focus')
      })
    }
  })
})
