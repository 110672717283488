// Action Cable provides the framework to deal with WebSockets in Rails.
// You can generate new channels where WebSocket features live using the `rails generate channel` command.

import { createConsumer } from '@rails/actioncable'

if (!window.App?.channels) window.App = { channels: {} }

if (!window.App.channels.consumer) window.App.channels.consumer = createConsumer()

export default window.App.channels.consumer
