import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    serialNumber: String,
    state: String,
  }

  static targets = ['loader', 'unreachable', 'form', 'error', 'confirmModal', 'confirmButton', 'alohaStatus']

  enabled = false

  connect() {
    this.fetchSettings()
  }

  async fetchSettings() {
    this.stateValue = 'loading'

    try {
      const res = await fetch(`/systems/${this.serialNumberValue}/control/settings?settings_type=maintenance`)
      const result = await res.json()

      if (!res.ok) {
        throw new Error(result.response)
      }

      this.cacheSettings(result)

      this.stateValue = 'idle'
    } catch (err) {
      console.error(err)
      this.unreachableTarget.querySelector('.unreachable-title').innerHTML = err.message
      const notSupported = err.message.includes('not supported by this system')
      this.unreachableTarget.querySelector('[data-tooltip-target]').classList.toggle('is-hidden', notSupported)
      this.stateValue = 'unreachable'
    }
  }

  cacheSettings(result) {
    if (result.settings_mismatch) {
      this.errorTarget.classList.remove('is-hidden')
      this.errorTarget.innerHTML = result.settings_mismatch
    }

    const { response } = result

    if (!response) {
      throw new Error('Unable to fetch settings')
    }

    const err = response.find((r) => r.error)

    if (err) {
      throw new Error(err)
    }

    if (response.length === 0) {
      throw new Error('Unable to fetch settings')
    }

    const settings = response[0].maintenanceSettings

    if (!settings) {
      throw new Error('Maintenance mode is not supported by this system')
    }

    this.enabled = settings.enabled

    this.alohaStatusTarget.classList.toggle('has-text-danger', settings.enabled)
    this.alohaStatusTarget.classList.toggle('has-text-success', !settings.enabled)

    this.confirmButtonTarget.classList.toggle('is-success', settings.enabled)
    this.confirmButtonTarget.classList.toggle('is-danger', !settings.enabled)

    if (settings.enabled) {
      this.alohaStatusTarget.textContent = 'OFF'

      this.confirmButtonTarget.textContent = 'Disable maintenance mode'

      this.confirmModalTarget.querySelectorAll('[data-disable]').forEach((el) => {
        el.dataset.enable = el.textContent
        el.textContent = el.dataset.disable
      })
    } else {
      this.alohaStatusTarget.textContent = 'ON'

      this.confirmButtonTarget.textContent = 'Enable maintenance mode'

      this.confirmModalTarget.querySelectorAll('[data-enable]').forEach((el) => {
        el.dataset.disable = el.textContent
        el.textContent = el.dataset.enable
      })
    }
  }

  stateValueChanged() {
    switch (this.stateValue) {
      case 'loading':
        this.loaderTarget.classList.remove('is-hidden')
        this.unreachableTarget.classList.add('is-hidden')
        this.formTarget.classList.add('is-hidden')
        break
      case 'unreachable':
        this.loaderTarget.classList.add('is-hidden')
        this.unreachableTarget.classList.remove('is-hidden')
        this.formTarget.classList.add('is-hidden')
        break
      case 'idle':
        this.loaderTarget.classList.add('is-hidden')
        this.unreachableTarget.classList.add('is-hidden')
        this.formTarget.classList.remove('is-hidden')
        this.confirmButtonTarget.disabled = false
        break
      case 'updating':
        this.errorTarget.classList.add('is-hidden')
        this.confirmButtonTarget.textContent = 'Processing...'
        this.confirmButtonTarget.disabled = true
        break
    }
  }

  openConfirmModal() {
    this.confirmModalTarget.classList.add('is-active')
  }

  closeConfirmModal() {
    this.confirmModalTarget.classList.remove('is-active')
  }

  async confirm() {
    this.closeConfirmModal()

    this.stateValue = 'updating'

    try {
      const res = await fetch(`/systems/${this.serialNumberValue}/control/set_settings`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        },
        body: JSON.stringify({ settings: 'maintenance', enabled: !this.enabled }),
      })

      if (!res.ok) {
        throw new Error(`Unable to ${this.enabled ? 'disable' : 'enable'} maintenance mode`)
      }

      const newSettings = await res.json()

      this.cacheSettings(newSettings)

      this.stateValue = 'idle'
    } catch (err) {
      console.error(err)
      this.unreachableTarget.querySelector('.unreachable-title').innerHTML = err.message
      this.stateValue = 'unreachable'
    }
  }
}
