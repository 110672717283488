import { toast, setDoc } from 'bulma-toast'

const TYPES = {
  error: {
    class: 'is-danger',
    in: 'shake',
  },
  alert: {
    class: 'is-danger',
    in: 'shake',
  },
  success: {
    class: 'is-success',
  },
  notice: {
    class: 'is-info',
  },
}

export const showNotification = ({
  type,
  message,
  duration,
  position,
  dismissible,
  notificationClass,
  absolute = false,
}) => {
  const opts = TYPES[type]

  toast({
    message: message,
    type: opts?.class,
    dismissible,
    duration: duration || 2000,
    pauseOnHover: true,
    position: position || 'top-right',
    animate: { in: opts?.in || 'fadeIn', out: 'fadeOut' },
  })

  if (notificationClass) document.querySelector(`.notification`).classList.add(notificationClass)

  if (absolute) document.querySelector('.notification').parentNode.style.position = 'absolute'
}

export const showNotifications = (notifications) => {
  if (!notifications.length) return

  notifications.forEach(([type, message]) => showNotification({ type, message, dismissible: true }))
}

document.addEventListener('DOMContentLoaded', () => {
  if (window.App.flash) {
    showNotifications(window.App.flash)
  }

  Array.from(document.querySelectorAll('.notification .delete')).forEach(($delete) => {
    var $notification = $delete.parentNode

    $delete.addEventListener('click', () => {
      $notification.parentNode.removeChild($notification)
    })
  })
})
