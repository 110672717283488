import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['item']

  itemTargetConnected(element) {
    const list = [...this.element.children]
    const idx = list.indexOf(element)

    element.classList.toggle('is-first', idx === 0)
    element.classList.toggle('is-last', idx === list.length - 1)

    if (list.length > 1) {
      if (idx === 0) {
        list[1].classList.remove('is-first')
      } else if (idx === list.length - 1) {
        list[list.length - 2].classList.remove('is-last')
      }
    }
  }
}
