// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import '../stylesheets/application.sass'

require('../controllers/index.js')

require('../javascript/dropdowns')
require('../javascript/nav')

if (!window.App) window.App = {}

window.App.channels = {}

require('../javascript/organization-members')
require('../javascript/systems')
require('../javascript/hotjar')
require('../javascript/modals')
require('../javascript/forms')
require('../channels')
require('../javascript/activity-feeds')
require('../javascript/customers')
require('../javascript/users')
require('../javascript/looker')
require('../javascript/tables')

import { setCookie } from '../javascript/utils'

document.addEventListener('DOMContentLoaded', () => {
  document.querySelector('.preload-transitions')?.classList?.remove('preload-transitions')

  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
  setCookie('timezone', tz)

  const cookiesBanner = document.getElementById('cookies-banner')

  if (cookiesBanner) {
    function checkCookiesSupport(evt) {
      if (evt.data === 'MM:3PCunsupported') cookiesBanner.classList.remove('is-hidden')
    }

    window.addEventListener('message', checkCookiesSupport, false)
  }
})
