import { Controller } from '@hotwired/stimulus'
import { showNotification } from '../javascript/flash-notifications'

// this controller is used to provide ability to share links using native browser share dialog
// link to share is passed to the dialog as title and url
export default class extends Controller {
  // it uses stimulus parameters passed to the share button action
  share(event) {
    if (navigator.share) {
      event.preventDefault()

      const shareData = {
        title: event.params.title,
        url: event.params.url,
      }

      navigator.share(shareData)
    } else if (navigator.clipboard) {
      event.preventDefault()

      navigator.clipboard.writeText(event.params.url)

      showNotification({
        type: 'success',
        message: 'Link copied to clipboard',
        duration: 2000,
      })
    } else {
      // redirect to share url
      if (event.target.tagName !== 'A') window.location.href = event.params.url
    }
  }
}
