import { findElementWithParent } from './utils'
import bulmahead from './vendors/bulmahead'

const fetchCustomers = (query) =>
  fetch(`/customers/search?query=${query}&limit=10`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'same-origin',
  })
    .then(function (customers) {
      return customers.json()
    })
    .then(function (customers) {
      return customers.map(function (customer) {
        return { label: `${customer.first_name} ${customer.last_name}`, value: customer.id, obj: customer }
      })
    })

const toggleCustomerFormPartial = (id, hide) => {
  const el = document.getElementById(id)

  if (!el) return

  el.classList.toggle('is-hidden', hide)

  Array.from(el.querySelectorAll('input')).forEach((e, idx) => {
    if (!e.closest('.boolean')) e.value = null
    e.disabled = hide
  })

  el.querySelector("input:not([type='hidden'])").focus()
}

document.addEventListener('click', (e) => {
  const target = findElementWithParent(e, 'customer-fields-button')

  if (!target) return

  e.preventDefault()

  toggleCustomerFormPartial('customer-fields', false)

  document.getElementById('customer-search').value = null
})

document.addEventListener('DOMContentLoaded', () => {
  const customerSearch = document.getElementById('customer-search')

  if (!customerSearch) return

  const onSelect = (state) => {
    toggleCustomerFormPartial('customer-fields', false)

    document.getElementById(customerSearch.dataset.targetIdKey).value = state.value

    Object.entries(state.obj).forEach(([k, v]) => {
      const f = document.getElementById(`${customerSearch.dataset.targetFieldsKey}_${k}`)
      if (f) f.value = v
    })
  }

  bulmahead('customer-search', 'customer-search-menu', fetchCustomers, onSelect, 200, 0)
})
