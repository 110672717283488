import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    serialNumber: String,
    settingsSet: String,
    state: String,
    systemSettings: Object,
  }

  static targets = ['loader', 'unreachable', 'form', 'resetModal', 'error', 'settingsMismatch']

  initialFormData
  dirty = false

  connect() {
    this.fetchSettings()

    window.addEventListener('beforeunload', (event) => {
      if (this.dirty) {
        event.preventDefault()
        event.returnValue = ''
      }
    })
  }

  disconnect() {
    if (this.statusFetchTimer) {
      clearTimeout(this.statusFetchTimer)
    }
  }

  setSettingsForm() {
    for (const [key, value] of Object.entries(this.systemSettingsValue)) {
      if (key === 'mode') {
        const modeInput = this.formTarget.querySelector(`[name="${key}"][value="${value}"]`)
        if (modeInput) {
          modeInput.checked = true
        }
        this.toggleGeneratorMode(value)
        continue
      }

      if (key === 'enabled') {
        this.setHiddenEnabled(value)
      }

      const input = this.formTarget.querySelector(`[name="${key}"]:not([type="hidden"])`)

      if (input) {
        if (input.type === 'checkbox') {
          input.checked = value
        } else {
          input.value = value
        }
      }
    }

    this.initialFormData = this.getFormValues()
  }

  async fetchSettings() {
    this.stateValue = 'loading'

    try {
      const res = await fetch(
        `/systems/${this.serialNumberValue}/control/settings?settings_type=${this.settingsMode()}`
      )
      const result = await res.json()

      if (!res.ok) {
        throw new Error(result.response)
      }

      this.cacheSettings(result)

      this.stateValue = 'idle'
    } catch (err) {
      console.error(err)
      this.unreachableTarget.querySelector('.unreachable-title').innerHTML = err.message
      this.stateValue = 'unreachable'
    }

    this.statusFetchTimer = setTimeout(() => {
      if (this.stateValue !== 'loading') return

      this.unreachableTarget.querySelector('.unreachable-title').innerHTML =
        this.unreachableTarget.querySelector('.unreachable-title').dataset.defaultMessage
      this.stateValue = 'unreachable'
    }, 600)
  }

  settingsMode() {
    return this.formTarget.querySelector('[name="settings"]').value
  }

  settingsSet() {
    return this.settingsMode() === 'charging' ? 'chargeSettings' : 'generatorSettings'
  }

  cacheSettings(result) {
    if (result.settings_mismatch) {
      this.errorTarget.classList.remove('is-hidden')
      this.errorTarget.innerHTML = result.settings_mismatch
    }

    const { response } = result

    if (!response) {
      throw new Error('Unable to fetch settings')
    }

    const err = response.find((r) => r.error)

    if (err) {
      throw new Error(err)
    }

    if (response.length === 0) {
      throw new Error('Unable to fetch settings')
    }

    const settings = response[0][this.settingsSet()]

    this.systemSettingsValue = {
      enabled: settings.enabled,
      start_threshold: settings.startThreshold,
      stop_threshold: settings.stopThreshold,
      charging_current: settings.chargingCurrent,
      mode: settings.mode?.toLowerCase(),
    }

    this.setSettingsForm()
  }

  stateValueChanged() {
    switch (this.stateValue) {
      case 'loading':
        this.loaderTarget.classList.remove('is-hidden')
        this.unreachableTarget.classList.add('is-hidden')
        this.formTarget.classList.add('is-hidden')
        break
      case 'unreachable':
        this.loaderTarget.classList.add('is-hidden')
        this.unreachableTarget.classList.remove('is-hidden')
        this.formTarget.classList.add('is-hidden')
        break
      case 'idle':
        this.loaderTarget.classList.add('is-hidden')
        this.unreachableTarget.classList.add('is-hidden')
        this.formTarget.classList.remove('is-hidden')
        this.toggleSubmitButton()
        this.toggleFormDisable()
        break
      case 'updating':
        this.errorTarget.classList.add('is-hidden')
        this.toggleFormDisable(true)

        break
    }
  }

  getFormValues() {
    const formData = new FormData(this.formTarget.querySelector('form'))
    this.formTarget
      .querySelectorAll('input:not([disabled]):not([type="submit"]):not([type="hidden"]), select:not([disabled])')
      .forEach((input) => {
        const value = input.type === 'checkbox' || input.type === 'radio' ? input.checked : input.value
        formData.set(input.name, value)
      })
    return formData
  }

  onFormChange(event) {
    switch (event.target.name) {
      case 'enabled':
        this.disableFormFields(!event.target.checked)
        this.setHiddenEnabled(event.target.checked)
        break
      case 'mode':
        this.toggleGeneratorMode(event.target.value)
        break
    }

    this.toggleSubmitButton()

    this.validateForm(event)
  }

  setHiddenEnabled(enabled) {
    document.querySelector(`[name="enabled"][type='hidden']`).disabled = enabled
  }

  toggleGeneratorMode(mode) {
    const disabled = mode === 'manual'

    ;['start_threshold', 'stop_threshold'].forEach((name) => {
      const input = this.formTarget.querySelector(`[name="${name}"]`)
      input.disabled = disabled
      input.closest('.field').classList.toggle('is-disabled', disabled)
    })
  }

  validateForm(event) {
    if (event.target.name === 'start_threshold' || event.target.name === 'stop_threshold') {
      const stopThresholdEl = this.formTarget.querySelector('[name="stop_threshold"]')
      const startThresholdEl = this.formTarget.querySelector('[name="start_threshold"]')

      if (parseInt(stopThresholdEl.value, 10) <= parseInt(startThresholdEl.value, 10)) {
        stopThresholdEl.setCustomValidity('The Stop Threshold must be greater than the Start Threshold')
      } else {
        stopThresholdEl.setCustomValidity('')
      }
    }
  }

  isEnabled() {
    return this.formTarget.querySelector('[name="enabled"]:not([type="hidden"])').checked
  }

  disableFormFields(value) {
    const disabled = typeof value !== 'undefined' ? value : !this.isEnabled()

    this.formTarget
      .querySelectorAll(
        'select:not([data-always-enabled]), input:not([name="enabled"]):not([type="submit"]):not([type="hidden"])'
      )
      .forEach((input) => {
        input.disabled = disabled
        input.closest('.field').classList.toggle('is-disabled', disabled)
      })

    if (this.settingsMode() === 'generator' && !disabled)
      this.toggleGeneratorMode(this.formTarget.querySelector('[name="mode"]:checked').value)
  }

  toggleSubmitButton(disabled) {
    const btn = this.formTarget.querySelector('[type="submit"]')

    if (typeof disabled !== 'undefined') {
      btn.disabled = disabled
      return
    }

    const formValues = this.getFormValues()

    this.dirty = false

    for (const [key, value] of formValues.entries()) {
      if (this.initialFormData.get(key) !== value) {
        this.dirty = true
        break
      }
    }

    btn.disabled = !this.dirty
  }

  openResetModal() {
    this.resetModalTarget.classList.add('is-active')
  }

  closeResetModal() {
    this.resetModalTarget.classList.remove('is-active')
  }

  async resetSettings() {
    this.stateValue = 'updating'
    await this.sendReset()
    this.stateValue = 'idle'
    this.closeResetModal()
  }

  async sendReset() {
    try {
      const res = await fetch(`/systems/${this.serialNumberValue}/control/set_settings`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        },
        body: JSON.stringify({ settings: this.settingsMode(), reset: true }),
      })

      if (!res.ok) {
        throw new Error('Unable to reset settings')
      }

      const newSettings = await res.json()

      this.cacheSettings(newSettings)
    } catch (err) {
      console.error(err)
      this.unreachableTarget.querySelector('.unreachable-title').innerHTML = err.message
      this.stateValue = 'unreachable'
    }
  }

  toggleFormDisable(disabled) {
    this.disableFormFields(disabled)
    this.toggleSubmitButton(disabled)

    const enabledInput = this.formTarget.querySelector('input[name="enabled"]')
    enabledInput.disabled = disabled || false
    enabledInput.closest('.field').classList.toggle('is-disabled', disabled || false)

    this.formTarget.querySelectorAll('button').forEach((btn) => {
      btn.disabled = disabled || false
    })
  }

  beforeSettingsSet() {
    this.stateValue = 'updating'

    this.toggleSubmitButtonText()
  }

  afterSettingsSet() {
    this.stateValue = 'idle'

    this.toggleSubmitButtonText()
  }

  onSetSuccess(event) {
    const [, , xhr] = event.detail
    const resp = JSON.parse(xhr.response)
    this.cacheSettings(resp)
  }

  onSetError(event) {
    let [data, status, xhr] = event.detail

    this.errorTarget.classList.remove('is-hidden')
    this.errorTarget.innerHTML = data.response
  }

  toggleSubmitButtonText() {
    const btn = this.formTarget.querySelector('[type="submit"]')
    const disableWith = btn.dataset.disableBtnWith
    btn.dataset.disableBtnWith = btn.value
    btn.value = disableWith
  }
}
