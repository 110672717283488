import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['widgetContainer', 'widgetContent']

  initialized = false

  toggleWidget(e) {
    e.currentTarget.classList.toggle('is-active')

    this.widgetContainerTarget.classList.toggle('is-visible')

    if (this.initialized) return

    window.widgetOptions = {
      expandedWidth: '100%',
      expandedHeight: '100%',
    }

    this.initialize()
  }

  initialize() {
    this.initialized = true
  }
}
