import { Controller } from '@hotwired/stimulus'
import { formatDateTime, formatDistanceToNow } from '../javascript/datetime'

export default class extends Controller {
  static targets = ['time', 'timeAgo']
  static values = {
    timezone: String,
  }

  REFRESH_AGO_INTERVAL = 10000

  connect() {
    if (this.timeAgoTargets.length > 0) {
      this.startRefreshingAgo()
    }
  }

  disconnect() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  timeTargetConnected(element) {
    element.innerHTML = formatDateTime(element.dataset.time, this.timezoneValue)
  }

  timeAgoTargetConnected(element) {
    this.setTimeAgo(element)
  }

  setTimeAgo(element) {
    element.innerHTML = formatDistanceToNow(element.dataset.timeAgo)
  }

  startRefreshingAgo() {
    this.refreshTimer = setInterval(() => {
      this.timeAgoTargets.forEach((element) => {
        this.setTimeAgo(element)
      })
    }, this.REFRESH_AGO_INTERVAL)
  }
}
