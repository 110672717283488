import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="tabs"
//
export default class extends Controller {
  static targets = ['btn', 'tab', 'currentTabText']
  static values = { currentTabIdx: Number }

  connect() {
    // then, show the default tab
    const selectedTab = this.tabTargets[0]
    selectedTab.classList.remove('is-hidden')

    // and activate the selected button
    const selectedBtn = this.btnTargets[0]
    selectedBtn.classList.add('is-active')
  }

  // add to your buttons: data-action="click->tabs#select"
  select(event) {
    const selectedTab = this.tabTargets.find((t) => t.dataset['tabsTab'] == event.params.tab)

    if (!selectedTab) {
      console.error("Can't find tab")
      return
    }

    this.tabTargets.map((x) => x.classList.add('is-hidden'))

    selectedTab.classList.remove('is-hidden')

    this.btnTargets.forEach((b) => {
      const link = b.tagName === 'A' ? b : b.querySelector('a')
      b.classList.toggle('is-active', link.dataset['tabsTabParam'] === event.params.tab)
    })

    if (this.hasCurrentTabTextTarget) this.currentTabTextTarget.textContent = event.target.textContent

    const trigger = new CustomEvent('tabs:change', { detail: { tab: event.params.tab } })
    window.dispatchEvent(trigger)
  }
}
