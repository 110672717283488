import BulmaheadController from '../bulmahead_controller'

export default class extends BulmaheadController {
  api(query) {
    return fetch(`/crm/prospects/search?query=${query}&limit=10`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'same-origin',
    })
      .then(function (prospects) {
        return prospects.json()
      })
      .then(function (prospects) {
        return prospects.map(function (prospect) {
          return { label: `${prospect.first_name} ${prospect.last_name}`, value: prospect.id, obj: prospect }
        })
      })
  }
}
