import { showNotification } from './flash-notifications'

document.addEventListener('ajax:complete', ({ target, detail }) => {
  if (target.dataset.action !== 'resend_invitation') return

  const [data, status, xhr] = detail

  showNotification({
    type: data.error ? 'error' : 'notice',
    message: data.error || JSON.parse(data.responseText).notice,
    duration: 2000,
  })
})
