import { formatDistanceToNow as dateFnsFormatDistanceToNow, parseISO } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

const DATE_FORMAT = new Intl.DateTimeFormat('default', {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
})

const TIME_FORMAT = new Intl.DateTimeFormat('default', {
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
})

export const formatDateTime = (datetime, tz = null) => {
  if (!datetime) return null

  let dt = parseISO(datetime)

  if (tz) dt = utcToZonedTime(dt, tz)

  return `${DATE_FORMAT.format(dt)} ${TIME_FORMAT.format(dt)}`
}

export const formatDistanceToNow = (datetime) => {
  if (!datetime) return null

  const parsed = parseISO(datetime)
  return dateFnsFormatDistanceToNow(parsed, { addSuffix: true })
}

export const formatAllTimeAgo = () => {
  Array.from(document.querySelectorAll('[data-time-ago]')).forEach((el) => {
    if (el.dataset.timeAgo) el.innerHTML = formatDistanceToNow(el.dataset.timeAgo)
  })
}

export const formatAllTime = () => {
  const tz = document.querySelector('[data-time-format-timezone-value]')?.dataset?.timeFormatTimezoneValue

  Array.from(document.querySelectorAll('[data-time]')).forEach((el) => {
    if (el.dataset.time) el.innerHTML = formatDateTime(el.dataset.time, tz)
  })
}
