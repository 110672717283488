import { Controller } from '@hotwired/stimulus'
import { numberToHuman } from '../../javascript/utils'
import { showNotification } from '../../javascript/flash-notifications'

export default class extends Controller {
  static targets = [
    'tableSection',
    'applianceTemplate',
    'prospect',
    'appliances',
    'review',
    'next',
    'back',
    'publish',
    'preview',
    'potentialLoad',
    'loadPerDay',
    'steps',
    'proposalPreview',
    'proposalPreviewLoader',
  ]

  static values = {
    proposalId: String,
  }

  tableSectionTargetConnected() {
    this.selectedAppliances().forEach((row) => {
      const applianceId = row.querySelector('input[name*="[appliance_id]"]').value
      const tile = this.element.querySelector(`[data-appliance-id="${applianceId}"]`)

      if (tile) tile.classList.add('is-active')
    })

    this.tableSectionTarget.classList.toggle('is-hidden', !this.selectedAppliances().length)

    this.calculatePreview()
  }

  nextTargetConnected() {
    this.nextTarget.disabled = false

    if (!this.isFirstTab()) {
      this.switchToAppliances()
    }
  }

  selectAppliance(event) {
    let appliance = event.target.dataset.appliance

    if (!appliance) return

    appliance = JSON.parse(event.target.dataset.appliance)
    const applianceId = appliance['id']

    const applianceRow = this.tableSectionTarget.querySelector(
      `tbody tr input[name*="[appliance_id]"][value="${applianceId}"]`
    )

    if (applianceRow) {
      const row = applianceRow.closest('tr')

      // re-add previously removed appliance
      if (row.classList.contains('is-hidden')) {
        event.target.classList.add('is-active')

        row.querySelector('input[name*="_destroy"]').value = 0
        row.querySelector('input[name*="[hours_per_day]"]').value = appliance['hours_per_day']
        row.querySelector('input[name*="[quantity]"]').value = 1
        row.parentNode.appendChild(row)
        row.classList.remove('is-hidden')
      } else {
        event.target.classList.remove('is-active')

        // if persisted appliance, mark for deletion
        if (row.querySelector("input[name*='[id]'][value]")) {
          row.querySelector('input[name*="_destroy"]').value = 1
          row.classList.add('is-hidden')
        } else {
          row.remove()
        }
      }
    } else {
      event.target.classList.add('is-active')

      const content = this.applianceTemplateTarget.innerHTML
        .replace(/NEW_RECORD/g, this.tableSectionTarget.querySelectorAll('tbody tr').length)
        .replace(/NAME/g, appliance['name'])
        .replace(/SUGGESTED_POWER/g, appliance['power_text'])

      // Create a temporary container for the content
      const tmp = document.createElement('tr')
      tmp.innerHTML = content

      tmp.querySelector('input[name*="[power]"]').value = appliance['power']
      tmp.querySelector('input[name*="[appliance_id]"]').value = appliance['id']
      tmp.querySelector('input[name*="[hours_per_day]"]').value = appliance['hours_per_day']
      tmp.querySelector('input[name*="[quantity]"]').value = 1

      this.tableSectionTarget.querySelector('tbody').appendChild(tmp)
    }

    this.tableSectionTarget.classList.toggle('is-hidden', !this.selectedAppliances().length)

    this.calculatePreview()
  }

  selectedAppliances() {
    return this.tableSectionTarget.querySelectorAll('tbody tr:not(.is-hidden)')
  }

  calculatePreview() {
    const selectedAppliances = this.selectedAppliances()

    if (!selectedAppliances.length) {
      this.previewTarget.classList.add('is-hidden')
      return
    }

    this.previewTarget.classList.remove('is-hidden')

    let potentialLoad = 0
    let loadPerDay = 0

    selectedAppliances.forEach((row) => {
      const power = parseFloat(row.querySelector('input[name*="[power]"]').value) || 0
      const quantity = parseFloat(row.querySelector('input[name*="[quantity]"]').value) || 0
      const hours = parseFloat(row.querySelector('input[name*="[hours_per_day]"]').value) || 0

      potentialLoad += power * quantity
      loadPerDay += power * quantity * hours
    })

    this.potentialLoadTarget.textContent = numberToHuman(potentialLoad, 'W')
    this.loadPerDayTarget.textContent = numberToHuman(loadPerDay, 'Wh')
  }

  onProspectSelect(e) {
    const res = e.detail
    const prospect = res.obj

    this.element.querySelector('[name="crm_proposal[prospect_attributes][first_name]"]').value = prospect.first_name
    this.element.querySelector('[name="crm_proposal[prospect_attributes][last_name]"]').value = prospect.last_name
    this.element.querySelector('[name="crm_proposal[prospect_attributes][email]"]').value = prospect.email
    this.element.querySelector('[name="crm_proposal[prospect_attributes][phone]"]').value = prospect.phone
    this.element.querySelector('[name="crm_proposal[prospect_attributes][address]"]').value = prospect.address
  }

  isFirstTab() {
    return !this.prospectTarget.classList.contains('is-hidden')
  }

  isSecondTab() {
    return !this.appliancesTarget.classList.contains('is-hidden')
  }

  switchToProspect() {
    this.element.classList.remove('is-9')
    this.element.classList.add('is-6')

    this.appliancesTarget.classList.add('is-hidden')
    this.reviewTarget.classList.add('is-hidden')
    this.prospectTarget.classList.remove('is-hidden')

    // inputs are disabled to prevent validation of hidden inputs
    this.appliancesTarget.querySelectorAll('input, select').forEach((input) => {
      input.disabled = true
    })

    this.stepsTarget.querySelectorAll('.step-item').forEach((step, i) => {
      step.classList.toggle('is-active', i === 0)
      step.classList.remove('is-completed')
    })
  }

  switchToAppliances() {
    this.element.classList.remove('is-6')
    this.element.classList.add('is-9')
    this.prospectTarget.classList.add('is-hidden')
    this.appliancesTarget.classList.remove('is-hidden')
    this.reviewTarget.classList.add('is-hidden')

    this.backTarget.textContent = 'Back'

    // inputs are disabled to prevent validation of hidden inputs
    this.appliancesTarget.querySelectorAll('input, select').forEach((input) => {
      input.disabled = false
    })

    this.stepsTarget.querySelectorAll('.step-item').forEach((step, i) => {
      step.classList.toggle('is-active', i === 1)
      step.classList.toggle('is-completed', i === 0)
    })

    this.publishTarget.classList.add('is-hidden')
    this.publishTarget.disabled = true
  }

  async switchToReview() {
    this.appliancesTarget.classList.add('is-hidden')
    this.reviewTarget.classList.remove('is-hidden')

    this.nextTarget.value = 'Save as draft'
    this.backTarget.textContent = 'Back'

    // inputs are disabled to prevent validation of hidden inputs
    this.reviewTarget.querySelectorAll('input').forEach((input) => {
      input.disabled = false
    })

    this.stepsTarget.querySelectorAll('.step-item').forEach((step, i) => {
      step.classList.toggle('is-active', i === 2)
      step.classList.toggle('is-completed', i < 2)
    })

    this.publishTarget.classList.remove('is-hidden')
    this.publishTarget.disabled = false

    await this.loadPreview()
  }

  async onNext(e) {
    if (this.isFirstTab()) {
      e.preventDefault()

      const valid = this.element.querySelector('form').reportValidity()

      if (!valid) return

      this.switchToAppliances()
    } else if (this.isSecondTab()) {
      e.preventDefault()

      const valid = this.element.querySelector('form').reportValidity()

      if (!valid) return

      const selectedAppliances = this.selectedAppliances()

      if (!selectedAppliances.length) {
        showNotification({ type: 'error', message: 'Please select at least one appliance' })
        return
      }

      await this.switchToReview()
    }
  }

  onBack(e) {
    if (this.isFirstTab()) return

    e.preventDefault()

    e.target.textContent = 'Cancel'

    this.nextTarget.value = 'Next'

    if (this.isSecondTab()) {
      this.switchToProspect()
    } else {
      this.switchToAppliances()
    }
  }

  async loadPreview() {
    this.proposalPreviewTarget.innerHTML = null
    this.proposalPreviewTarget.classList.add('is-hidden')
    this.proposalPreviewLoaderTarget.classList.remove('is-hidden')

    const formData = new FormData(this.element.querySelector('form'))

    formData.set('proposal_id', this.proposalIdValue)
    formData.delete('_method')

    const response = await fetch(`/crm/proposals/preview`, {
      method: 'POST',
      credentials: 'same-origin',
      body: formData,
    }).then((res) => res.text())

    this.proposalPreviewLoaderTarget.classList.add('is-hidden')
    this.proposalPreviewTarget.classList.remove('is-hidden')
    this.proposalPreviewTarget.innerHTML = response
  }
}
