import consumer from './consumer'
import { htmlToElement } from '../javascript/utils'
import { recalculateTopNavFeed } from '../javascript/activity-feeds'

export const subscribeToOrganizationMember = (organization) => {
  const feed = document.querySelector('#topnav-notifications-list')

  if (!feed) return

  window.App.channels.organizationMember?.unsubscribe()

  window.App.channels.organizationMember = consumer.subscriptions.create(
    { channel: 'OrganizationMemberChannel', organization },
    {
      received({ notifications }) {
        if (notifications.feed) {
          feed.innerHTML = notifications.feed
        }

        if (notifications.item) {
          feed.prepend(htmlToElement(notifications.item))
        }

        recalculateTopNavFeed()
      },
    }
  )
}
