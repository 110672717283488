// github.com/mattmezza/bulmahead/blob/master/src/bulmahead.js

import debounce from 'lodash.debounce'

const bulmahead = (id, idMenu, api, onSelect, delay, minLen = 2) => {
  const input = document.getElementById(id)
  const menuEl = document.getElementById(idMenu)

  const toggleMenu = (hide) => {
    menuEl.style.display = hide ? 'none' : 'block'
  }

  toggleMenu(true)

  const setValue = (e) => {
    e.preventDefault()
    var label = e.target.innerHTML
    input.value = label

    if (onSelect) {
      const value = e.target.dataset.value
      const obj = e.target.dataset.obj
      onSelect({ label, value, obj: obj ? JSON.parse(obj) : undefined })
    }

    toggleMenu(true)

    return false
  }

  const handleApi = (e) => {
    const value = e.target.value

    if (value.length <= minLen) {
      return
    }

    api(value).then((suggestions) => {
      menuEl.innerHTML = '<div class="dropdown-content bulmahead-dropdown"><ul></ul></div>'

      const suggestionsEl = suggestions.map(({ label, value, obj }) => {
        const li = document.createElement('li')
        li.classList.add('dropdown-item')
        li.innerHTML = label
        li.dataset.value = value
        if (obj) li.dataset.obj = JSON.stringify(obj)
        return li
      })
      suggestionsEl.map((suggEl) => {
        menuEl.querySelector('ul').appendChild(suggEl)
      })

      toggleMenu()

      if (!suggestions.length) {
        const noResultsEl = document.createElement('div')
        noResultsEl.classList.add('dropdown-item')
        noResultsEl.innerHTML = 'No results'
        menuEl.childNodes[0].appendChild(noResultsEl)
      }
    })
  }
  input.addEventListener('input', debounce(handleApi, delay))

  input.addEventListener('focusin', () => toggleMenu())

  menuEl.addEventListener('click', (e) => {
    if (!e.target.classList.contains('dropdown-item')) return

    setValue(e)
  })

  document.addEventListener('click', (e) => {
    if (!e.target.closest('.dropdown') && (e.target === null || !e.target.classList.contains('dropdown-item'))) {
      toggleMenu(true)
    }
  })
}

export default bulmahead
