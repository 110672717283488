const hasModalCloseClass = (el) => {
  if (
    el.classList.contains('modal-background') ||
    el.classList.contains('modal-close') ||
    el.hasAttribute('data-modal-close')
  )
    return true

  if (el.classList.contains('delete') && el.closest('.modal-card-head')) return true

  if (el.classList.contains('button') && el.closest('.modal-card-foot')) return true

  return false
}

export const closeModals = () => {
  document.documentElement.classList.remove('is-clipped')

  Array.from(document.querySelectorAll('.modal')).forEach((el) => {
    const modalCardBody = el.querySelector('.modal-card-body')
    if (modalCardBody) {
      modalCardBody.scrollTop = 0
    }

    el.classList.remove('is-active')

    const event = new Event('modal:close', { bubbles: true })

    el.dispatchEvent(event)
  })
}

document.addEventListener('click', ({ target }) => {
  const el = target.classList.contains('modal-button') ? target : target.closest('.modal-button')

  if (!el || !el.classList.contains('modal-button')) return

  const modal = document.getElementById(el.dataset.target)
  document.documentElement.classList.add('is-clipped')
  modal.classList.add('is-active')

  if (el.dataset.modalCardBody) {
    modal.querySelector('.modal-card-body').innerHTML = el.dataset.modalCardBody
  }

  const event = new Event('modal:show', { bubbles: true })

  modal.dispatchEvent(event)
})

document.addEventListener('click', ({ target }) => {
  const el = hasModalCloseClass(target) ? target : target.closest('.modal-button')

  if (!el || !hasModalCloseClass(el)) return

  closeModals()
})

document.addEventListener('keydown', (event) => {
  var e = event || window.event
  if (e.keyCode === 27) {
    closeModals()
  }
})
