export const htmlToElement = (html) => {
  const template = document.createElement('template')
  html = html.trim() // Never return a text node of whitespace as the result
  template.innerHTML = html
  return template.content.firstChild
}

export const setCookie = (name, value, expires, path, domain, secure) => {
  document.cookie =
    name +
    '=' +
    escape(value) +
    (expires ? '; expires=' + expires.toGMTString() : '') +
    (path ? '; path=' + path : '') +
    (domain ? '; domain=' + domain : '') +
    (secure ? '; secure' : '')
}

export function truncate(str, max) {
  return str.length > max ? str.slice(0, max - 1) + '…' : str
}

export function findElementWithParent(e, className) {
  const parentToggleTrigger = e.target.closest(`.${className}`)
  const toggle = parentToggleTrigger || e.target
  return toggle.classList.contains(className) ? toggle : null
}

export function numberToHuman(value, uom) {
  if (!value) return

  if (value < 1000) return `${value} ${uom}`

  const result = value / 1000

  return `${result.toFixed(2)} k${uom}`
}
