import { Controller } from '@hotwired/stimulus'
import { fetchSystemStatus } from '../javascript/systems'

export default class extends Controller {
  static values = {
    serialNumber: String,
    state: String,
    systemState: Object,
  }

  static targets = ['loader', 'unreachable', 'form']

  connect() {
    if (this.stateValue === 'loading') this.fetchStatus()
  }

  disconnect() {
    if (this.gridControlModalTimer) {
      clearTimeout(this.gridControlModalTimer)
    }
  }

  async fetchStatus() {
    await fetchSystemStatus('systems', this.serialNumberValue)

    this.gridControlModalTimer = setTimeout(() => {
      if (this.stateValue !== 'loading') return

      this.loaderTarget.classList.add('is-hidden')

      this.stateValue = 'unreachable'
      this.unreachableTarget.classList.remove('is-hidden')
    }, 60000)
  }

  beforePortSend(e) {
    this.loaderTarget.classList.remove('is-hidden')
    this.formTarget.remove()

    this.loaderTarget.querySelector('strong').textContent =
      e.target.textContent == 'Open port' ? 'Opening port...' : 'Closing port...'
  }
}
