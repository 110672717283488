import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  filter(event) {
    event.preventDefault()

    const formData = new FormData(this.element)

    const frame = document.getElementById('dwh_monitor_chart')

    const url = new URL(frame.src)
    url.searchParams.set('from', formData.get('from'))
    url.searchParams.set('to', formData.get('to'))

    frame.innerHTML = '<div class="section py-5"<p>Loading...</p></div>'
    frame.src = url.toString()
    frame.reload()
  }
}
