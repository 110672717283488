window.addEventListener('message', (event) => {
  const iframes = Array.from(document.querySelectorAll('[data-looker-src]'))
  const iframe = iframes.find((i) => i.contentWindow === event.source)

  if (iframe && event.origin === 'https://offgridelectric.eu.looker.com') {
    const { type, height } = JSON.parse(event.data) || {}

    if (type === 'page:changed') {
      const prevSibling = iframe.previousElementSibling
      if (prevSibling?.classList?.contains('loader')) prevSibling.remove()
    }

    if (type === 'page:properties:changed' && height) iframe.height = height
  }
})
