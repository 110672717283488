document.addEventListener('click', ({ target: el }) => {
  if (!el.classList.contains('navbar-burger')) return

  // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
  el.classList.toggle('is-active')
  el.parentNode.classList.toggle('is-active')
})

// keeps track of whether user is pressing cmd/ctrl key
let cmdPressed = false

document.addEventListener('keydown', (e) => {
  cmdPressed = e.ctrlKey || e.metaKey
})

document.addEventListener('keyup', function () {
  cmdPressed = false
})

document.addEventListener('click', ({ target }) => {
  if (target.tagName === 'A') return

  const el = target.dataset.href ? target : target.closest('[data-href]')

  if (!el || el.tagName === 'A') return

  if (cmdPressed) {
    window.open(el.dataset.href)
  } else {
    window.location = el.dataset.href
  }

  cmdPressed = false
})
