import { Controller } from '@hotwired/stimulus'
import { showNotification } from '../javascript/flash-notifications'

export default class extends Controller {
  static targets = [
    'error',
    'loader',
    'unreachable',
    'form',
    'lockStatus',
    'lockForm',
    'unlockForm',
    'refetchButton',
    'operatingTime',
  ]

  static values = {
    serialNumber: String,
    state: String,
    status: String,
    operatingTime: String,
  }

  connect() {
    this.fetchStatus()
  }

  disconnect() {
    if (this.statusFetchTimer) {
      clearTimeout(this.statusFetchTimer)
    }
  }

  toggleFormDisabled(disabled = true) {
    this.element.querySelectorAll('input, a, button').forEach((el) => {
      if (disabled) el.setAttribute('disabled', 'disabled')
      else el.removeAttribute('disabled')
      el.classList.toggle('is-disabled', disabled)
    })
  }

  beforeSend({ target }) {
    this.toggleFormDisabled()

    let buttonTarget = target

    if (buttonTarget.tagName === 'FORM') {
      buttonTarget = buttonTarget.querySelector('button[type="submit"]')
    }

    if (!buttonTarget) {
      console.error('Button target not found')
      return
    }

    buttonTarget.dataset.originalText = buttonTarget.textContent
    buttonTarget.textContent = 'In progress...'

    this.errorTarget.textContent = ''
    this.errorTarget.classList.add('is-hidden')
  }

  afterSend({ target }) {
    this.toggleFormDisabled(false)

    let buttonTarget = target

    if (buttonTarget.tagName === 'FORM') {
      buttonTarget = buttonTarget.querySelector('button[type="submit"]')
    }

    if (!buttonTarget) {
      console.error('Button target not found')
      return
    }

    buttonTarget.removeAttribute('disabled')
    buttonTarget.classList.remove('is-disabled')

    buttonTarget.textContent = buttonTarget.dataset.originalText
  }

  onSendError(e) {
    const [data, ,] = e.detail

    if (data.response) {
      this.errorTarget.textContent = data.response
      this.errorTarget.classList.remove('is-hidden')
    }
  }

  onSendSuccess({ detail }) {
    const message = this.statusValue === 'locked' ? 'System successfully unlocked' : 'System successfully locked'

    showNotification({ type: 'success', message })

    const [, , xhr] = detail
    const result = JSON.parse(xhr.response)

    this.stateValue = 'idle'
    this.statusValue = result.lock_status
    this.operatingTimeValue = result.operating_time
  }

  async fetchStatus() {
    this.stateValue = 'loading'
    this.statusValue = undefined
    this.operatingTimeValue = undefined

    try {
      const res = await fetch(`/systems/${this.serialNumberValue}/fetch_lock_status`)
      const result = await res.json()

      if (!res.ok) {
        const error = result.error ? result.error.replaceAll('\n', '<br>') : 'Unknown error'
        throw new Error(error)
      }

      this.stateValue = 'idle'
      this.statusValue = result.lock_status
      this.operatingTimeValue = result.operating_time
    } catch (err) {
      console.error(err)
      this.unreachableTarget.querySelector('.unreachable-title').innerHTML = err.message
      this.stateValue = 'unreachable'
    }

    this.statusFetchTimer = setTimeout(() => {
      if (this.stateValue !== 'loading') return

      this.unreachableTarget.querySelector('.unreachable-title').innerHTML =
        this.unreachableTarget.querySelector('.unreachable-title').dataset.defaultMessage
      this.stateValue = 'unreachable'
    }, 30000)
  }

  stateValueChanged() {
    switch (this.stateValue) {
      case 'loading':
        this.loaderTarget.classList.remove('is-hidden')
        this.unreachableTarget.classList.add('is-hidden')
        this.refetchButtonTarget.classList.add('is-hidden')
        this.formTarget.classList.add('is-hidden')
        break
      case 'unreachable':
        this.loaderTarget.classList.add('is-hidden')
        this.unreachableTarget.classList.remove('is-hidden')
        this.refetchButtonTarget.classList.remove('is-hidden')
        this.formTarget.classList.add('is-hidden')
        break
      case 'idle':
        this.loaderTarget.classList.add('is-hidden')
        this.unreachableTarget.classList.add('is-hidden')
        this.formTarget.classList.remove('is-hidden')
        break
      case 'updating':
        this.errorTarget.classList.add('is-hidden')
        this.refetchButtonTarget.classList.add('is-hidden')
        break
    }
  }

  statusValueChanged() {
    switch (this.statusValue) {
      case 'locked':
        this.refetchButtonTarget.classList.add('is-hidden')
        this.lockStatusTarget.innerHTML = 'Locked'
        this.lockStatusTarget.classList.remove('has-text-success')
        this.lockStatusTarget.classList.add('has-text-danger')
        this.lockFormTarget.classList.add('is-hidden')
        this.unlockFormTarget.classList.remove('is-hidden')
        this.operatingTimeTarget.classList.add('is-hidden')
        break
      case 'unknown':
        this.refetchButtonTarget.classList.remove('is-hidden')
        this.lockStatusTarget.innerHTML = 'Unknown'
        this.lockStatusTarget.classList.remove('has-text-danger')
        this.lockStatusTarget.classList.remove('has-text-success')
        this.lockFormTarget.classList.add('is-hidden')
        this.unlockFormTarget.classList.add('is-hidden')
        this.operatingTimeTarget.classList.add('is-hidden')
        break
      case 'temporarily_unlocked':
        this.refetchButtonTarget.classList.add('is-hidden')
        this.lockStatusTarget.innerHTML = 'Unlocked'
        this.lockStatusTarget.classList.remove('has-text-danger')
        this.lockStatusTarget.classList.add('has-text-success')
        this.lockFormTarget.classList.remove('is-hidden')
        this.unlockFormTarget.classList.add('is-hidden')
        this.operatingTimeTarget.classList.remove('is-hidden')
        break
      case 'permanently_unlocked':
        this.refetchButtonTarget.classList.add('is-hidden')
        this.lockStatusTarget.innerHTML = 'Unlocked'
        this.lockStatusTarget.classList.remove('has-text-danger')
        this.lockStatusTarget.classList.add('has-text-success')
        this.lockFormTarget.classList.remove('is-hidden')
        this.unlockFormTarget.classList.add('is-hidden')
        this.operatingTimeTarget.classList.add('is-hidden')
        break
    }
  }

  operatingTimeValueChanged() {
    if (this.statusValue === 'locked' || this.statusValue === 'unknown') return

    if (this.operatingTimeValue) {
      this.operatingTimeTarget.querySelector('strong').innerHTML = this.operatingTimeValue
    }
  }
}
