import { Controller } from '@hotwired/stimulus'
import '../packs/highcharts.js'
import { toDate, toFloat, processAlignMinOption, updateRangeParamsInURL } from '../javascript/chartkick-utils.js'

export default class extends Controller {
  static targets = ['dashboard', 'dateRange']
  static values = {
    reportUrl: String,
    options: String,
    lazyLoad: Boolean,
  }

  dashboard = null
  initialized = false

  async connect() {
    for (const title of document.getElementsByClassName('highcharts-group-title')) {
      title.parentNode.parentNode.classList.add('no-shadow')
    }

    if (this.reportUrlValue && !this.lazyLoadValue) {
      await this.initReport()
    }
  }

  async initReport() {
    if (this.initialized) return

    this.initialized = true

    this.setLoading()

    const reportData = await this.fetchReportData()
    if (reportData.error) {
      console.error(reportData.error)
      this.setError()
      this.dispatch('loadingComplete')
      return
    }

    if (!reportData.data) {
      console.error('No data found in the report')
      return
    }

    if (reportData.dashboard_structure) {
      this.setStructure(this.dashboardTarget, reportData.dashboard_structure)
    }

    for (const { component } of this.dashboard.mountedComponents) {
      const componentData = reportData.data[component.id]

      if (component.type === 'KPI') {
        component.update({ value: toFloat(componentData) })
      } else if (component.type !== 'HTML') {
        const chart = component.chart
        while (chart.series.length) {
          chart.series[0].remove()
        }
        this.setChartData(chart, componentData)
      }
    }

    this.dispatch('loadingComplete')
  }

  async onRangeChange({ detail: { content } }) {
    if (this.reportUrlValue) {
      this.initialized = false
      this.reportUrlValue = updateRangeParamsInURL(this.reportUrlValue, content)
      return this.initReport()
    }
  }

  setError() {
    const loadingContainer = document.createElement('div')
    loadingContainer.classList.add('highcharts-placeholder')
    loadingContainer.classList.add('highcharts-error')

    const loadingEl = document.createElement('span')
    loadingEl.innerHTML = 'Something went wrong. Please try again later.'

    loadingContainer.appendChild(loadingEl)
    this.dashboardTarget.replaceChildren(loadingContainer)
  }

  setLoading() {
    if (!this.dashboard) {
      const loadingContainer = document.createElement('div')
      loadingContainer.classList.add('highcharts-placeholder')

      const loadingEl = document.createElement('span')
      loadingEl.classList.add('highcharts-loading-inner')
      loadingEl.innerHTML = 'Loading...'

      loadingContainer.appendChild(loadingEl)
      this.dashboardTarget.replaceChildren(loadingContainer)
      return
    }

    for (const { component } of this.dashboard.mountedComponents) {
      if (component.chart) {
        while (component.chart.series.length) {
          component.chart.series[0].remove()
        }
        component.chart.showLoading()
      } else if (component.type === 'KPI') {
        const loadingContainer = document.createElement('div')
        loadingContainer.classList.add('highcharts-loading')
        loadingContainer.style.position = 'absolute'
        loadingContainer.style.top = '50%'
        loadingContainer.style.left = '50%'
        loadingContainer.style.transform = 'translate(-50%, -50%)'
        loadingContainer.style.fontSize = '1rem'

        const loadingEl = document.createElement('span')
        loadingEl.classList.add('highcharts-loading-inner')
        loadingEl.innerHTML = 'Loading...'

        loadingContainer.appendChild(loadingEl)
        component.element.replaceChildren(loadingContainer)
      }
    }
  }

  setChartData(chart, data) {
    for (const serie of data) {
      if (chart.userOptions.chart.type === 'line' || chart.userOptions.chart.type === 'column') {
        serie.data = Object.entries(serie.data).map((d) => [toDate(d[0]).getTime(), toFloat(d[1])])
      }

      if (chart.userOptions.chart.type === 'line') {
        serie.marker ??= { symbol: 'circle', enabled: false }
      }

      chart.addSeries(serie)
      processAlignMinOption(chart)
    }

    chart.hideLoading()
  }

  setStructure(target, options) {
    if (this.dashboard) {
      this.dashboard.destroy()
    }
    const dashboard = Dashboards.board(target, options)
    this.dashboard = dashboard
  }

  dashboardTargetConnected(target) {
    if (!this.optionsValue) return
    this.setStructure(target, JSON.parse(this.optionsValue))
  }

  fetchReportData() {
    return fetch(this.reportUrlValue).then((response) => {
      if (response.ok) {
        return response.json()
      }

      return { error: response.statusText }
    })
  }

  async onTabChange(event) {
    if (event.detail.tab !== event.params.tab) return

    if (this.lazyLoadValue) {
      await this.initReport()
    }

    if (!this.dashboard) return

    this.dashboard.mountedComponents.forEach(({ component }) => {
      if (component.type === 'KPI') {
        component.resize()
      }
    })
  }
}
