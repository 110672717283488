import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form', 'list', 'map']

  openList() {
    this.listTarget.classList.remove('is-hidden')
    this.mapTarget.classList.add('is-hidden')
  }

  buttonClick(e) {
    Array.from(e.target.parentNode.children).forEach((button) => {
      button.classList.remove('is-primary')
    })
    e.target.classList.add('is-primary')
    const socInput = document.querySelector('input[name="soc"]')
    const value = e.target.getAttribute('value')
    if (value) {
      socInput.setAttribute('value', value)
    } else {
      socInput.removeAttribute('value')
    }
  }

  search() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.requestSubmit()
    }, 500)
  }

  requestSubmit() {
    this.formTarget.requestSubmit()
  }
}
