// close all dropdowns that were open on click
export const closeAllDropdowns = () => {
  Array.from(document.querySelectorAll('.dropdown:not(.is-hoverable)')).forEach((el) => {
    el.classList.remove('is-active')
  })
}

// close all dropdowns that were open on click when user clicks anywhere outside of dropdown
document.addEventListener('click', (e) => {
  const parentTrigger = e.target.closest('.dropdown-trigger')

  const target = parentTrigger || e.target

  if (!target.classList.contains('dropdown-trigger')) {
    return closeAllDropdowns()
  } else {
    e.stopPropagation()
  }

  const dropdown = target.closest('.dropdown:not(.is-hoverable)')
  dropdown.classList.toggle('is-active')
})

// close all dropdowns that were open on click when user clicks Esc
document.addEventListener('keydown', (event) => {
  const e = event || window.event

  if (e.key === 'Esc' || e.key === 'Escape') {
    closeAllDropdowns()
  }
})
