import { Controller } from '@hotwired/stimulus'
import Chartkick from 'chartkick'
import { processAlignMinOption, updateRangeParamsInURL } from '../javascript/chartkick-utils.js'

export default class extends Controller {
  static targets = ['chart', 'dateRange']
  static values = {
    reportUrl: String,
    lazyLoad: Boolean,
  }

  charts = []
  initialized = false

  async connect() {
    if (this.reportUrlValue && !this.lazyLoadValue) {
      await this.initReport()
    }
  }

  async initReport() {
    if (this.initialized) return

    this.initialized = true

    for (const chart of this.charts) {
      chart.element.innerHTML = chart.options.loading
    }

    const reportData = await this.fetchReportData()

    for (const chart of this.charts) {
      if (reportData.error) {
        this.setChartError(chart, reportData.error)
      } else {
        chart.updateData(reportData.filter((data) => data.chart === chart.element.id))
        processAlignMinOption(chart.chart)
      }
    }

    this.dispatch('loadingComplete')
  }

  setChartError(chart, message) {
    message = 'Error Loading Chart: ' + message
    chart.element.innerHTML = message
    chart.element.style.color = '#ff0000'
  }

  chartTargetConnected(target) {
    const data = target.dataset.data == 'report' ? null : target.dataset.data

    const chart = new Chartkick[target.dataset.type](target, data, JSON.parse(target.dataset.options))

    if (data === null) {
      chart.element.innerHTML = chart.options.loading
    }

    this.charts.push(chart)
  }

  fetchReportData() {
    return fetch(this.reportUrlValue).then((response) => {
      if (response.ok) {
        return response.json()
      }

      return { error: response.statusText }
    })
  }

  async onRangeChange({ detail: { content } }) {
    if (this.reportUrlValue) {
      this.initialized = false
      this.reportUrlValue = updateRangeParamsInURL(this.reportUrlValue, content)
      return this.initReport()
    }

    for (const chart of this.charts) {
      const dataSource = chart.getDataSource()

      if (typeof dataSource !== 'string') return

      chart.updateData(updateRangeParamsInURL(dataSource, content))
      processAlignMinOption(chart.chart)
    }
  }

  async onTabChange(event) {
    if (this.lazyLoadValue && event.detail.tab === event.params.tab) {
      await this.initReport()
    }
  }
}
