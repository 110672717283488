document.addEventListener('DOMContentLoaded', () => {
  const editUserForm = document.querySelector('form.edit_user')

  if (!editUserForm) return

  document.addEventListener('change', (e) => {
    const el = e.target

    if (!el.classList.contains('is-checkradio') || el.value === 'feed') return

    const feedCheckbox = editUserForm.querySelector(`input.is-checkradio[name='${el.name}'][value='feed']`)

    if (el.checked) {
      feedCheckbox.checked = true
    }

    feedCheckbox.classList.toggle('is-disabled', el.checked)
    feedCheckbox.parentNode.dataset.tooltipTarget = el.checked ? 'tooltip' : ''
  })
})
