import { findElementWithParent } from '../javascript/utils'

document.addEventListener('ajax:send', (e) => {
  const btn = findElementWithParent(e, 'activity-dismiss')

  if (!btn) return

  const item = btn.closest('.timeline-item')

  if (item) {
    item.remove()
    recalculateTopNavFeed()
  }
})

export const recalculateFirstLast = (feed) => {
  Array.from(feed.querySelectorAll('.timeline-item')).forEach((el, idx, list) => {
    el.classList.remove('is-first', 'is-last')

    if (idx === 0) el.classList.add('is-first')
    if (idx === list.length - 1) el.classList.add('is-last')
  })
}

export const recalculateTopNavFeed = () => {
  const notificationsContainer = document.getElementById('topnav-notifications')

  if (!notificationsContainer) return

  const placeholder = document.getElementById('topnav-notifications-placeholder')
  const notificationsIcon = notificationsContainer.querySelector('.fa-bell')

  const hasItems = !!notificationsContainer.querySelector('.timeline-item')

  placeholder.classList.toggle('is-hidden', hasItems)

  notificationsIcon.classList.toggle('has-notifications', hasItems)
}

document.addEventListener('change', (e) => {
  if (e.target.id !== 'activity-kind-select') return

  const hideTypes = e.target.value !== ''

  const typesChoice = window.choices.find((c) => c._baseId === 'choices--activity-types-select')

  hideTypes ? typesChoice.disable() : typesChoice.enable()

  document.getElementById('activity-type-select').classList.toggle('is-hidden', hideTypes)
})
