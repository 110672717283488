import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  hiddenClass = 'is-hidden'
  product
  phase

  static targets = [
    'productSelector',
    'phaseSelector',
    'devicesForm',
    'batteriesForm',
    'batteryFields',
    'serialNumber',
    'addDeviceBtn',
    'addBatteryBtn',
    'installationPhase',
    'devicePhase',
    'edgeFields',
    'checksumHint',
    'removeDeviceBtn',
    'batteryLabels',
  ]

  MAX_BATTERIES = 10

  isEdge() {
    return this.product === 'edge'
  }

  threePhase() {
    return this.phase === '3'
  }

  changeProduct(e) {
    this.product = e.target.value
    this.phase = undefined

    this.devicesFormTarget.querySelectorAll('input').forEach((input) => {
      input.value = ''
      input.checked = false
      input.classList.remove('is-danger')
    })

    this.batteriesFormTarget.querySelectorAll('input').forEach((input) => {
      input.value = ''
    })

    this.devicesFormTarget.querySelectorAll('.help').forEach((errors) => {
      errors.remove()
    })

    this.devicesFormTarget.querySelectorAll('.nested-form-wrapper ~ .nested-form-wrapper').forEach((field) => {
      field.remove()
    })

    this.batteriesFormTarget.querySelectorAll('.nested-form-wrapper ~ .nested-form-wrapper').forEach((field) => {
      field.remove()
    })

    this.toggleEdgeFields()
    this.toggleDevicePhases()
    this.toggleSerialNumber()

    if (this.hasChecksumHintTarget)
      this.checksumHintTarget.classList.toggle(this.hiddenClass, !this.product.startsWith('flex'))
  }

  changePhase(e) {
    this.phase = e.target.value

    this.toggleDevicePhases()
  }

  productSelectorTargetConnected(element) {
    this.product = element.value
  }

  phaseSelectorTargetConnected(element) {
    if (element.checked) this.phase = element.value
  }

  devicePhaseTargetConnected(element) {
    this.toggleDevicePhase(element)
  }

  removeDeviceBtnTargetConnected(element) {
    if (element != this.removeDeviceBtnTargets[0]) {
      element.classList.remove(this.hiddenClass)
    }
  }

  toggleDevicePhases() {
    this.devicePhaseTargets.forEach((element) => this.toggleDevicePhase(element))
  }

  toggleDevicePhase(element) {
    const disabled = !this.threePhase()

    element.classList.toggle(this.hiddenClass, disabled)
    element.querySelectorAll('select').forEach((input) => {
      input.disabled = disabled
      input.classList.toggle('disabled', disabled)
    })
  }

  toggleSerialNumber() {
    const isEdge = this.isEdge()

    this.serialNumberTarget.classList.toggle(this.hiddenClass, isEdge)
    this.serialNumberTarget.querySelectorAll('input').forEach((input) => {
      if (input.name.includes('[id]')) return

      input.disabled = isEdge
      input.classList.toggle('disabled', isEdge)
    })
  }

  // TODO: dry following funcs
  toggleEdgeFields() {
    const isEdge = this.isEdge()

    this.toggleInstallationPhase(isEdge)

    this.edgeFieldsTargets.forEach((element) => {
      element.querySelectorAll('input, select').forEach((input) => {
        if (input.name.includes('[id]')) return

        input.disabled = !isEdge
        input.classList.toggle('disabled', !isEdge)
      })

      element.classList.toggle(this.hiddenClass, !isEdge)
    })

    this.devicesFormTarget.querySelectorAll('input').forEach((input) => {
      if (input.name.includes('[id]')) return

      input.disabled = !isEdge
      input.classList.toggle('disabled', !isEdge)
    })

    this.batteriesFormTarget.querySelectorAll('input').forEach((input) => {
      input.disabled = !isEdge
      input.classList.toggle('disabled', !isEdge)
    })

    this.devicesFormTarget.classList.toggle(this.hiddenClass, !isEdge)
    this.batteriesFormTarget.classList.toggle(this.hiddenClass, !isEdge)
    this.addDeviceBtnTarget.classList.toggle(this.hiddenClass, !isEdge)
    this.addBatteryBtnTarget.classList.toggle(this.hiddenClass, !isEdge)
  }

  toggleInstallationPhase(isEdge) {
    this.installationPhaseTarget.querySelectorAll('input').forEach((input) => {
      input.disabled = !isEdge
      input.classList.toggle('disabled', !isEdge)
      input.checked = input.value === '1'
    })
    this.installationPhaseTarget.classList.toggle(this.hiddenClass, !isEdge)
  }

  batteryFieldsTargetConnected() {
    this.batteryLabelsTarget.classList.remove(this.hiddenClass)

    if (this.batteryFieldsTargets.length >= this.MAX_BATTERIES) {
      this.addBatteryBtnTarget.classList.add(this.hiddenClass)
    }
  }

  batteryFieldsTargetDisconnected() {
    if (this.batteryFieldsTargets.length === 0) {
      this.batteryLabelsTarget.classList.add(this.hiddenClass)
    }
  }
}
