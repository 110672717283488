import tippy from 'tippy.js'
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['tooltip']

  tooltipTargetConnected(element) {
    this.initializeTooltip(element)
  }

  tooltipTargetDisconnected(element) {
    element._tippy.destroy()
  }

  initializeTooltip(el) {
    return tippy(el, {
      theme: 'light',
      placement: el.dataset.tooltipPlacement,
      content: el.dataset.tooltipText,
      allowHTML: el.dataset.tooltipAllowHtml,
    })
  }
}
