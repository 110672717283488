var DATE_PATTERN = /^(\d\d\d\d)(?:-)?(\d\d)(?:-)?(\d\d)$/i

function toStr(obj) {
  return '' + obj
}

export function toDate(obj) {
  if (obj instanceof Date) {
    return obj
  } else if (typeof obj === 'number') {
    return new Date(obj * 1000) // ms
  } else {
    var s = toStr(obj)
    var matches = s.match(DATE_PATTERN)
    if (matches) {
      var year = parseInt(matches[1], 10)
      var month = parseInt(matches[2], 10) - 1
      var day = parseInt(matches[3], 10)
      return new Date(year, month, day)
    } else {
      // try our best to get the str into iso8601
      // TODO be smarter about this
      var str = s.replace(/ /, 'T').replace(' ', '').replace('UTC', 'Z')
      // Date.parse returns milliseconds if valid and NaN if invalid
      return new Date(Date.parse(str) || s)
    }
  }
}
export function toFloat(obj) {
  return parseFloat(obj)
}

export function processAlignMinOption(chart) {
  if (chart.yAxis.length < 2 || !chart.series.length) {
    return
  }

  for (let i = 0; i < 2; i++) {
    if (chart.yAxis[i]['options']['alignMin']) {
      const n = i ? 0 : 1
      const min = (chart.yAxis[n].min / chart.yAxis[n].max) * chart.yAxis[i].max
      if (typeof min === 'number') {
        chart.yAxis[i].update({ min })
      }
      break
    }
  }
}

export function updateRangeParamsInURL(source, content) {
  const url = new URL(source)
  for (const param of ['start_date', 'end_date', 'period_num', 'period_type']) {
    url.searchParams.delete(param)
  }
  if (content.start && content.end) {
    url.searchParams.set('start_date', content.start)
    url.searchParams.set('end_date', content.end)
  } else if (content.periodNum && content.periodType) {
    url.searchParams.set('period_num', content.periodNum)
    url.searchParams.set('period_type', content.periodType)
  }
  return url.toString()
}
