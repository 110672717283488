import { showNotification } from './flash-notifications'
import { closeAllDropdowns } from './dropdowns'
import { closeModals } from './modals'

document.addEventListener('ajax:beforeSend', ({ target }) => {
  if (target.id === 'system-lock-action-link' || target.id === 'fetch-config-link') closeAllDropdowns()

  if (target.id !== 'system-lock-action-link') return

  target.setAttribute('disabled', true)
  target.innerHTML = 'In progress...'
})

document.addEventListener('ajax:complete', ({ target, detail }) => {
  if (target.id !== 'fetch-config-link') return

  const [data, status, xhr] = detail

  if (data.error) {
    showNotification({ type: 'error', message: error, duration: 5000, position: 'bottom-right' })
  } else {
    showNotification({
      type: 'success',
      message: 'Request to fetch device config was sent',
      duration: 5000,
    })
  }
})

// keep lock link as-is because `ajax:complete` is not fired in document if target were removed
document.addEventListener('DOMContentLoaded', () => {
  const lockActionLink = document.getElementById('system-lock-action-link')

  if (lockActionLink) {
    lockActionLink.addEventListener('ajax:complete', (e) => {
      const [data, status, xhr] = e.detail

      if (data.error) {
        lockActionLink.setAttribute('disabled', false)
        lockActionLink.innerHTML = lockActionLink.dataset.action === 'system-unlock' ? 'Unlock' : 'Lock'

        showNotification({ type: 'error', message: data.error, duration: 5000, position: 'bottom-right' })
      } else {
        showNotification({
          type: 'success',
          message:
            lockActionLink.dataset.action === 'system-unlock'
              ? 'Request to unlock device was sent'
              : 'Request to lock device was sent',
          duration: 5000,
        })
      }
    })
  }
})

document.addEventListener('modal:show', ({ target }) => {
  const iframeUrl = target.dataset.iframeUrl

  if (!iframeUrl) return

  const iframe = target.querySelector('iframe')

  if (!iframe || iframe.src) return

  iframe.addEventListener('load', () => {
    target.querySelector('.loading')?.remove()
    iframe.style.opacity = 1
  })

  iframe.src = iframeUrl
})

document.addEventListener('ajax:error', ({ target, detail }) => {
  switch (target.id) {
    case 'remove-system-form':
    case 'set-intertie-form':
    case 'send-passcode-email-form':
      const [data, status, xhr] = detail

      showNotification({ type: 'error', message: data.error, duration: 5000 })
      break
  }
})

document.addEventListener('ajax:success', ({ target }) => {
  let message

  switch (target.id) {
    case 'send-passcode-email-form':
      message = 'Email with passcode was sent'
      break
    case 'set-intertie-form':
      message = 'Request to set intertie schedule was sent'
      break
    case 'reboot-system-form':
      message = 'Request to reboot system was sent'
      break
  }

  if (message) {
    showNotification({
      type: 'success',
      message,
      duration: 5000,
    })

    closeModals()
  }
})

export const fetchSystemStatus = (model = 'systems', serialNumber) => {
  return fetch(`/${model}/${serialNumber}/fetch_status`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'same-origin',
  })
}

document.addEventListener('change', ({ target: { id, value } }) => {
  if (id !== 'systems-filter-status-selector') return

  document.getElementById('soc-slider').classList.toggle('is-hidden', value === 'unreachable')

  Array.from(document.getElementById('soc-slider').querySelectorAll('input')).forEach((e) =>
    e.setAttribute('disabled', value === 'unreachable')
  )
})
