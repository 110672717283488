import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form']

  isLoading = false

  search(e) {
    e.preventDefault()

    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.requestSubmit()
    }, 500)
  }

  requestSubmit() {
    this.formTarget.requestSubmit()
  }

  setLoading(e) {
    this.isLoading = !this.isLoading

    this.element.querySelector('.fa-spin').classList.toggle('is-hidden', !this.isLoading)
    this.element.querySelector('.fa-search').classList.toggle('is-hidden', this.isLoading)
  }
}
