import { Controller } from '@hotwired/stimulus'
import { fetchSystemStatus } from '../javascript/systems'

export default class extends Controller {
  static values = {
    model: String,
    identifier: String,
  }
  static targets = ['loader', 'lastFetched', 'btn']
  static classes = ['loading']

  TIMEOUT = 30000

  oldLastFetched

  refresh(e) {
    e.preventDefault()

    e.stopPropagation()

    this.toggleUI()

    fetchSystemStatus('systems', this.identifierValue)
      .then(() => {
        if (typeof this.oldLastFetched !== 'undefined') {
          this.lastFetchedTarget.innerHTML = this.oldLastFetched
        } else {
          this.oldLastFetched = this.lastFetchedTarget.innerHTML
        }

        setTimeout(() => {
          this.toggleUI()
          this.lastFetchedTarget.innerText = `No response in ${this.TIMEOUT / 1000} seconds`
          delete this.lastFetchedTarget.dataset['timeFormatTarget']
        }, this.TIMEOUT)
      })
      .catch(() => {
        this.toggleUI()
        this.lastFetchedTarget.innerText = 'Error fetching status'
        delete this.lastFetchedTarget.dataset['timeFormatTarget']
      })
  }

  toggleUI() {
    this.btnTarget.classList.toggle('is-hidden')
    this.loaderTarget.classList.toggle('is-hidden')
    this.lastFetchedTarget.classList.toggle('is-hidden')
  }
}
