import '../stylesheets/highcharts.sass'

import Chartkick from 'chartkick'
import Highcharts from 'highcharts'
window.Highcharts = Highcharts

import Exporting from 'highcharts/modules/exporting'
Exporting(Highcharts)

import DataExporting from 'highcharts/modules/export-data'
DataExporting(Highcharts)

import '@highcharts/dashboards'
// it's not working other way
require('@highcharts/dashboards/modules/dashboards-plugin')

Chartkick.use(Highcharts)

Highcharts.Templating.helpers.localized = (value) => value.toLocaleString()
Highcharts.Templating.helpers.round2 = (value, numbers) => +value.toFixed(numbers)
