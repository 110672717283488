import { findElementWithParent } from './utils'

document.addEventListener('click', (e) => {
  handleTableRowClick(e)

  const sorter = findElementWithParent(e, 'sorter')

  if (!sorter) return

  if (!sorter.dataset.direction) {
    sorter.dataset.direction = 'asc'
  } else if (sorter.dataset.direction === 'asc') {
    sorter.dataset.direction = 'desc'
  } else {
    delete sorter.dataset.direction
  }

  const url = new URL(window.location.href)
  const searchParams = url.searchParams

  if (!sorter.dataset.direction) {
    searchParams.delete('sort')
    searchParams.delete('direction')
  } else {
    searchParams.set('sort', sorter.dataset.sort)
    searchParams.set('direction', sorter.dataset.direction)
  }

  window.location = url.toString()
})

function handleTableRowClick(e) {
  const rowTarget = e.target.dataset.rowToggle

  if (!rowTarget) return

  e.preventDefault()

  const row = document.getElementById(rowTarget)
  row.classList.toggle('is-hidden')
}
