import { Controller } from '@hotwired/stimulus'
import bulmaCalendar from 'bulma-calendar'

export default class extends Controller {
  static targets = [
    'calendar',
    'calendarControl',
    'periodControls',
    'updateButton',
    'periodOrRange',
    'startInput',
    'endInput',
  ]

  static values = {
    periodNum: Number,
    periodType: { type: String, default: 'hours' },
    startDate: String,
    endDate: String,
  }

  typeChanged(event) {
    this.calendarControlTarget.classList.toggle('is-hidden')
    this.periodControlsTarget.classList.toggle('is-hidden')
    this.enableUpdateButton()
  }

  calendarTargetConnected(target) {
    // TODO: options should be configurable from UI
    const calendars = bulmaCalendar.attach(target, {
      dateFormat: 'dd/MM/yyyy',
      type: 'date',
      isRange: true,
      maxDate: new Date(),
      showTodayButton: false,
      showClearButton: false,
      showFooter: false,
    })

    this.calendar = calendars[0]

    this.calendar.on('save', this.onRangeChanged.bind(this))
  }

  disconnect() {
    if (!this.calendar) return

    this.calendar.off('save', this.onRangeChanged.bind(this))
    this.calendar.destroy()
  }

  onPeriodNumChanged(event) {
    this.periodNumValue = Number(event.target.value) || 0
    this.enableUpdateButton()
  }

  onPeriodTypeChanged(event) {
    this.periodTypeValue = event.target.value
  }

  disableUpdateButton() {
    this.updateButtonTarget.setAttribute('disabled', 'true')
  }

  enableUpdateButton() {
    this.updateButtonTarget.removeAttribute('disabled')
  }

  onUpdateButtonClick() {
    this.disableUpdateButton()
    const content = {}
    if (this.periodOrRangeTarget.options.selectedIndex) {
      content['start'] = this.startDateValue
      content['end'] = this.endDateValue
    } else {
      content['periodNum'] = this.periodNumValue
      content['periodType'] = this.periodTypeValue
    }
    this.dispatch('change', { detail: { content: content } })
  }

  onRangeChanged(event) {
    this.startDateValue = event.data.date.start?.toLocaleDateString('sv')
    this.endDateValue = event.data.date.end?.toLocaleDateString('sv')

    this.enableUpdateButton()

    if (this.hasStartInputTarget) {
      this.startInputTarget.value = this.startDateValue
    }

    if (this.hasEndInputTarget) {
      this.endInputTarget.value = this.endDateValue
    }

    // If there is no update button, dispatch the change event immediately
    if (!this.hasUpdateButtonTarget) {
      this.dispatch('change', { detail: { content: { start: this.startDateValue, end: this.endDateValue } } })
    }
  }
}
