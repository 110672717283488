// https://github.com/stimulus-components/stimulus-rails-nested-form/blob/master/src/index.ts
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    max: Number,
  }
  static targets = ['target', 'template', 'add']

  formsCount() {
    return this.element.querySelectorAll('.nested-form-wrapper').length
  }

  add(e) {
    e.preventDefault()

    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, this.formsCount())
    this.targetTarget.insertAdjacentHTML('beforebegin', content)
    this.targetTarget.previousElementSibling.querySelector('input').focus()

    this.toggleAddButton()
  }

  remove(e) {
    e.preventDefault()

    const wrapper = e.target.closest('.nested-form-wrapper')

    if (!wrapper.dataset.newRecord || wrapper.dataset.newRecord === 'true') {
      wrapper.remove()
    } else {
      wrapper.style.display = 'none'

      const input = wrapper.querySelector("input[name*='_destroy']")
      if (input) input.value = '1'

      wrapper.querySelectorAll('input, select').forEach((input) => {
        if (input.name.match(/_destroy/) || input.name.match(/\[id\]/)) return

        input.disabled = true
      })
    }

    this.toggleAddButton()
  }

  toggleAddButton() {
    if (this.hasMaxValue) {
      this.addTarget.classList.toggle('is-hidden', this.formsCount() >= this.maxValue)
    }
  }
}
